import React from 'react';
class CardModel extends React.Component {
  render() {
    const {title, price, text, url, subtitle, another_price, custom_link_text, underline, type} = this.props;
    let sub_header;
    if(subtitle && another_price){
      sub_header = (
        <div className="card__model__sub__header__div">
          <h4 className="card__model__sub__header__title">{subtitle}</h4>
          <h4 className="card__model__sub__header__price"><b>{another_price}</b></h4>
        </div>
      )
    }
    let card_model_links;
    if(custom_link_text && underline === false){
      card_model_links = (
        <div className="card__model__links">
          <p className="card__model__links-custom__text">{custom_link_text}</p>
        </div>
      )
    }
    else {
      card_model_links = (
        <div className="card__model__links">
          <a href={url ? url : "https://pds.eventsbmw.com/"} target="_blank" rel="noopener noreferrer">See Available Dates</a>
          <hr />
          <a className="phone-number" href="tel:888-345-4269">Call to Book</a>
        </div>
      )
    }
    let content = '';
    if(type === 'two_day'){
      content = (
        <p className="card__model__content__paragrapgh">{text}</p>
      )
    }
    else {
      content = (
        <p>{text}</p>
      )
    }
    return (
      <div className="card__model__item">
        <div className="card__model">
          <div className="card__model__header">
            <div className="card__model__header__div">
              <h4 className="card__model__header__title">{title}</h4>
              <h4 className="card__model__header__price"><b>{price}</b></h4>
            </div>
            {sub_header}
          </div>
          <div className="card__model__content">
            {content}
            {card_model_links}
          </div>
        </div>
      </div>
    );
  }
}

export default CardModel;
