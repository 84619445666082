import React from 'react';
class SchoolLicenseCardModel extends React.Component {
  render() {
    const {title, price, text, url, subtitle, another_price, hide_links} = this.props;
    let sub_header;
    if(subtitle && another_price){
      sub_header = (
        <div className="school-license-card__model__sub__header__div">
          <h4 className="school-license-card__model__sub__header__title">{subtitle}</h4>
          <h4 className="school-license-card__model__sub__header__price"><b>{another_price}</b></h4>
        </div>
      )
    }
    let card_model_links;
    if(hide_links === "true"){
      card_model_links = (
        <div className="school-license-card__model__links">
          <a className="school-license-card__model__links-custom__text" href="/mschool/racelicenseschool">Learn More</a>
        </div>
      )
    }
    else {
      card_model_links = (
        <div className="card__model__links">
          <a href={url ? url : "https://pds.eventsbmw.com/"} target="_blank" rel="noopener noreferrer">See Available Dates</a>
          <hr />
          <a href="tel:888-345-4269" className="phone-number">Call to Book</a>
          <hr />
          <a href="/mschool/racelicenseschool">Learn More</a>
        </div>
      )
    }
    return (
      <div className="school-license-card__model__item">
        <div className="school-license-card__model">
          <div className="school-license-card__model__header">
            <div className="school-license-card__model__header__div">
              <h4 className="school-license-card__model__header__title">{title}</h4>
              <h4 className="school-license-card__model__header__price"><b>{price}</b></h4>
            </div>
            {sub_header}
          </div>
          <div className="school-license-card__model__content">
            <p>{text}</p>
            {card_model_links}
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolLicenseCardModel;